import { openNav, closeNav } from "./modules/navigation.js";
import ShopifyBuy from "@shopify/buy-button-js";

// Stop transitions before page has loaded
$(window).on("load", function () {
  $("body").removeClass("preload");
});

// Toggle navigation
$("#hamburger").click(function (e) {
  e.preventDefault();
  $($("#main-navigation").hasClass("hidden") ? openNav() : closeNav());
});

// Reset if browser is resized
$(window).resize(function () {
  if ($(window).width() >= 1024) {
    closeNav();
  }
});

// Accordion

$(".accordion-toggle").click(function () {
  const accordion = $(this).parent();

  accordion.find(".accordion-body").slideToggle();
  $(this).find(".open, .close").toggle();
});

// Slideshows
const activeClass = "!opacity-100";

var swiper = new Swiper(".swiper.carousel", {
  loop: true,
  slidesPerView: "auto",
  spaceBetween: 10,
  threshold: 2,
  navigation: {
    nextEl: ".carousel-next",
    prevEl: ".carousel-prev",
  },
  pagination: {
    el: ".carousel-pagination",
    type: "fraction",
    formatFractionCurrent: function (number) {
      return number < 10 ? "0" + number : number;
    },
    formatFractionTotal: function (number) {
      return number < 10 ? "0" + number : number;
    },
  },
  on: {
    init: function (swiper) {
      for (i = 0; i < swiper.loopedSlides; i++) {
        swiper.$el
          .find(".carousel-bullets")
          .append(
            '<span class="carousel-bullet block flex-1 h-1 mr-1 bg-current opacity-25 transition cursor-pointer"></span>'
          );
        swiper.$el.find(".carousel-bullet").eq(0).addClass(activeClass);
      }
    },
    slideChange: function (swiper) {
      swiper.$el.find(".carousel-bullet").removeClass(activeClass);
      swiper.$el
        .find(".carousel-bullet")
        .eq(swiper.realIndex)
        .addClass(activeClass);
    },
  },
});

$(document).on("click", ".carousel-bullet", function () {
  swiper.slideTo($(this).index());
});

// Shopify
const button = $("#buy-button");

if (button.length) {
  var client = ShopifyBuy.buildClient({
    domain: "fraser-taylor.myshopify.com",
    storefrontAccessToken: "f6aa3d3d59f77e23d370dcb8a133b3b3",
  });

  var ui = ShopifyBuy.UI.init(client);

  ui.createComponent("product", {
    id: button.data("product"),
    node: button[0],
    options: {
      product: {
        contents: {
          img: false,
          price: false,
          title: false,
          options: false,
        },
        text: {
          button: "Add to basket",
        },
        iframe: false,
        templates: {
          button:
            '<button class="shopify-buy__btn button block mt-12 text-center">Add to basket</button>',
        },
        events: {
          addVariantToCart: function (product) {
            this.updateVariant(
              button.data("variant-option"),
              button.data("variant-value")
            );
          },
        },
      },
      option: {},
      cart: {
        styles: {
          button: {
            "border-radius": "0px",
          },
        },
        text: {
          total: "Subtotal",
          button: "Checkout",
        },
      },
      toggle: {},
    },
  });
}
