const hamburger = $("#hamburger");
const nav = $("#main-navigation");
const header = $("#site-header");

export function openNav() {
  nav.removeClass("hidden");
  header.addClass("h-screen bg-black text-white");
  hamburger.find(".close").removeClass("hidden");
  hamburger.find(".open").addClass("hidden");
}

export function closeNav() {
  nav.addClass("hidden");
  header.removeClass("h-screen bg-black text-white");
  hamburger.find(".open").removeClass("hidden");
  hamburger.find(".close").addClass("hidden");
}
